import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import isNull from 'lodash/isNull';
import uniqueId from 'lodash/uniqueId';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

// Config
import { styleConfig } from '../../config/config';

// Components
import MuiButton from './MuiButton';
import Title from './Common/Title';
import RouteLink from './RouteLink';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 5,

    '& > img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      objectPosition: 'center center',
    },
  },
  ctaBg: {
    backgroundColor: styleConfig.color.bg.darkgrey,
  },
  cta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 500,

    [theme.breakpoints.down('md')]: {
      padding: 75,
    },

    [theme.breakpoints.down('sm')]: {
      padding: 5,
    },
  },
  inner: {
    zIndex: 15,

    '& h2': {
      textTransform: 'uppercase',
      marginBottom: '4rem',
    },
  },
  buttons: {
    '& > a': {
      marginRight: 30,
    },
  },
}));

const ContentModulesCallToActionPrimary: React.FunctionComponent<ContentModulesCallToActionPrimaryProps> = (
  props
) => {
  const classes = useStyles();
  const { subtitle, headline, buttons, image } = props;
  const imageObj = getImage(image);
  const imageBg = convertToBgImage(imageObj);

  return (
    <BackgroundImage fadeIn={false} Tag="section" className={classes.ctaBg} {...imageBg}>
      <div className={classes.cta}>
        <Container maxWidth="lg" className={classes.inner}>
          <Grid container justifyContent="center" alignItems="center" direction="column">
            {!isNull(headline) && !isNull(subtitle) && headline && (
              <Title
                component="h2"
                alignment="center"
                isSubtitle
                subtitleText={subtitle}
                subtitleType="subtitle1"
                titleColor="white"
                titleHeavy
                titleSize="h3"
                titleText={headline}
              />
            )}

            <div className={classes.buttons}>
              {buttons &&
                buttons.map(
                  (block, index) =>
                    block.button &&
                    block.button.url && (
                      <RouteLink key={uniqueId()} href={block.button.url}>
                        <MuiButton
                          aria-label={block && block.button && block.button.title}
                          height={61}
                          labelColor="#FFF"
                          name={`buttons-group-${index}`}
                          variant={block.type === 'block' ? 'contained' : 'outlined'}
                        >
                          <div dangerouslySetInnerHTML={{ __html: block.button.title }} />
                        </MuiButton>
                      </RouteLink>
                    )
                )}
            </div>
          </Grid>
        </Container>
      </div>
    </BackgroundImage>
  );
};

interface ContentModulesCallToActionPrimaryProps {
  subtitle: string;
  headline: string;
  buttons?: {
    type: string;
    button: {
      url: string;
      title: string;
    };
  }[];
  image: any;
}

export default ContentModulesCallToActionPrimary;
